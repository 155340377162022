<template>
    <v-container>
        <form slot="leftContainer" autocomplete="nope" @submit.prevent="getReporteSelectBank" class="my-2">
            <v-card>
                <v-card-title class="grey lighten-4">
                    <b>MOVIMIENTOS BANCARIOS</b>
                    <v-spacer />
                </v-card-title>
                <v-card-text class="pa-3">
                    <v-row dense align="center">
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                dense
                                v-model="form.idSede"
                                v-validate="'required'"
                                :items="empresa"
                                item-text="description"
                                item-value="idSede"
                                :error-messages="errors.collect('form.idSede')"
                                label="EMPRESA"
                                data-vv-name="form.idSede"
                                data-vv-as="description"
                                required
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-menu
                                ref="menu"
                                v-model="menu"
                                :close-on-content-click="false"
                                transition="scale-transition"
                                offset-y
                                max-width="290px"
                                min-width="auto"
                            >
                                <template #activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="fechaFiltro"
                                        hide-details="auto"
                                        dense
                                        label="FILTRAR POR RANGO - MES"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    />
                                </template>
                                <v-date-picker v-model="fechaFiltro" range no-title scrollable>
                                    <v-spacer />
                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-autocomplete
                                outlined
                                dense
                                v-validate="'required'"
                                v-model="form.detalle"
                                item-text="description"
                                item-value="id"
                                :items="allBank"
                                label="BANCO"
                                required
                            />
                        </v-col>
                        <v-col cols="12" sm="3">
                            <v-btn type="submit" :loading="loading" dark color="primary" block :disabled="!form.fecha">
                                <b>{{ 'BUSCAR' }}</b>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </form>
    </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'

import { format, subDays, addDays } from 'date-fns'
import DefaultForm from '@/utils/defaultForm'

export default {
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            fechaFiltro: [format(subDays(new Date(), 31), 'yyyy-MM-dd'), format(addDays(new Date(), 1), 'yyyy-MM-dd')],
            menu: false,
            fecha: [],
            loading: false,
            registro: '',
            form: DefaultForm.formReport(),
            dialog: false
        }
    },

    computed: {
        ...mapState('utilities', ['allBank', 'empresa'])
    },
    methods: {
        async getReporteSelectBank() {
            try {
                //console.log('this.form----', this.form)
                await this.$store.dispatch('reporte/reporteBanco', {
                    idSede: this.form.idSede,
                    fechaInicio: this.fechaFiltro[0],
                    fechaFin: this.fechaFiltro[1] ?? this.fechaFiltro[0],
                    idBanco: this.form.detalle
                })
                this.form = DefaultForm.formReport()
                this.loading = false
            } catch (error) {
                this.loading = false
                console.error(error)
            }
        }
    }
}
</script>
